import { clone } from '../../utils/clone';

export const PAGINATE_NO_LIMIT = 1000000;

export interface PaginateObject<T> {
  columnValues?: PaginatedColumnValueIndex[];
  filter?: string; // Should contain the RSQL queries.
  page: {
    content?: T[],
    number?: number,
    size?: number,
    sort?: PaginatedSortOption[],
    numberOfElements?: number,
    totalElements?: number,
    totalPages?: number,
    first?: boolean,
    last?: boolean,
  };

  // Exclusively for overview tables.
  scope?: string;
  groupId?: number;
}

export interface PaginatedSortOption {
  sortOrder: 'ASC' | 'DESC';
  propertyName: string;
  nullsFirst: boolean;
  ignoreCase?: boolean;
}

export interface PaginatedColumnValueIndex {
  field: string;
  values?: any[];
  provideValuesForDropdown?: boolean;
}

export interface SortParams {
  field: string;
  order: number;
  nullsFirst?: boolean;
}

/**
 * Class for handling pagination data requests and responses.
 * Type for the paginated data must be specified.
 *
 * Direct access to the pagination object is not allowed.
 * Any adjustments to the paginated data must come through here.
 * This provides us flexibility when adding features or functions to the paginated request/response in the future.
 */
export class PaginatedData<T> {

  private paginationObject: PaginateObject<T>;

  constructor(opts?: PaginateObject<T>) {
    if (opts) {
      this.paginationObject = clone(opts);
    } else {
      // Get the default settings
      this.setupDefaults();
    }
  }

  public get size(): number {
    return this.paginationObject.page.size;
  }

  public set size(size: number) {
    this.paginationObject.page.size = size;
  }

  public get page(): number {
    return this.paginationObject.page.number;
  }

  public set page(num: number) {
    this.paginationObject.page.number = num;
  }

  public get query(): string {
    return this.paginationObject.filter;
  }

  public set query(query: string) {
    this.paginationObject.filter = query;
  }

  public get scope(): string {
    return this.paginationObject.scope;
  }

  public set scope(scope: string) {
    this.paginationObject.scope = scope;
  }

  public get scopeId(): number {
    return this.paginationObject.groupId;
  }

  public set scopeId(id: number) {
    this.paginationObject.groupId = id;
  }

  public get content(): T[] {
    return this.paginationObject.page.content;
  }

  public get total(): number {
    return this.paginationObject.page.totalElements;
  }

  public toObject(): PaginateObject<T> {
    if (!this.paginationObject.groupId) {
      delete this.paginationObject.groupId;
    }

    // This method is often called to create the HTTP request
    // Make sure we clear all the data when creating the request object.
    const requestObject = clone(this.paginationObject) as PaginateObject<T>;

    // Make sure to clear the column values
    for (const colVal of requestObject.columnValues) {
      colVal.values = [];
    }

    // Strip all the current content data.
    requestObject.page.content = [];

    // Cleanup.
    // Need to comment this out to not flicker the paginator
    // delete requestObject.page.totalElements;
    // delete requestObject.page.numberOfElements;
    // delete requestObject.page.totalPages;
    // delete requestObject.page.first;
    // delete requestObject.page.last;

    return requestObject;
  }

  /**
   * Safely update the value of the current pagination option
   * Most used when updating the instance with a backend response.
   * @param value
   */
  public update(value: PaginateObject<T>): PaginatedData<T> {
    // We don't really need update the whole object as we keep the current ones we have on the instance.
    this.paginationObject.page.content = value.page.content;
    this.paginationObject.page.totalElements = value.page.totalElements;
    this.paginationObject.page.first = value.page.first;
    this.paginationObject.page.last = value.page.last;
    // this.paginationObject.columnValues = value.columnValues;

    for (const responseValue of value.columnValues) {
      this.updateColumnValue(responseValue);
    }

    return this;
  }

  /**
   * Single field sorting.
   * @param field
   * @param order
   * @param nullsFirst
   */
  public sort(field: string, order: number, nullsFirst: boolean = false): void {
    this.paginationObject.page.sort = [];
    this.paginationObject.page.sort = [
      { propertyName: field, sortOrder: this.translateSortOrder(order), nullsFirst: !!nullsFirst }
    ];
  }

  public getCurrentSort(): { field: string, sort: number, nullsFirst: boolean } {
    const sort = this.paginationObject.page.sort[0];

    if (sort) {
      return {
        field: sort.propertyName,
        sort: this.translateSortOrderReverse(sort.sortOrder),
        nullsFirst: !!sort.nullsFirst
      };
    }
    return null;
  }

  public sortMulti(sortParams: SortParams[]): void {
    this.paginationObject.page.sort = sortParams.map(param => {
      return {
        propertyName: param.field,
        sortOrder: this.translateSortOrder(param.order),
        nullsFirst: !!param.nullsFirst
      };
    });
  }

  public getCurrentMultiSort(): SortParams[] {
    if (!(Array.isArray(this.paginationObject.page.sort))) {
      return [];
    }

    return this.paginationObject.page.sort.map(sort => {
      return {
        field: sort.propertyName,
        order: this.translateSortOrderReverse(sort.sortOrder)
      };
    });
  }

  public clearSort(): void {
    this.paginationObject.page.sort = [];
  }

  public addColumnValue(field: string, values?: any[], provideValuesForDropdown: boolean = false): void {
    // Check first if the field is existing already.
    const existingValue = this.paginationObject.columnValues.find(cvalue => cvalue.field === field);

    // Override values if existing
    if (existingValue) {
      existingValue.values = values || existingValue.values || [];
      return;
    }

    // Otherwise add to the column value objects
    this.paginationObject
      .columnValues
      .push(
        { field, values: values || [], provideValuesForDropdown }
      );
  }

  public removeColumnValue(field: string): void {
    this.paginationObject.columnValues = this.paginationObject
      .columnValues
      .filter(cvalue => cvalue.field !== field);
  }

  public getColumnValue(field: string): any[] {
    const colDef = this.paginationObject.columnValues.find(col => col.field === field);
    if (colDef) {
      return colDef.values;
    }

    return [];
  }

  public getColumnValues(): PaginatedColumnValueIndex[] {
    return clone(this.paginationObject.columnValues) as PaginatedColumnValueIndex[];
  }

  /**
   * Override this value on specific cases.
   */
  protected setupDefaults(): void {
    this.paginationObject = {
      columnValues: [],
      filter: '',
      page: {
        content: [],
        number: 0,
        size: 50,
        sort: [],
        totalElements: 0
      }
    };
  }

  private translateSortOrder(order: number | string): 'ASC' | 'DESC' {
    if (order === 1) {
      return 'ASC';
    }

    return 'DESC';
  }

  private translateSortOrderReverse(order: string): number {
    if (order === 'ASC') {
      return 1;
    }

    return -1;
  }

  private updateColumnValue(newValue: PaginatedColumnValueIndex): void {
    const updateValue = this.paginationObject.columnValues.find(listValue => listValue.field === newValue.field);
    if (updateValue) {
      updateValue.values = newValue.values;
    }
  }
}
