import { ErrorHandler, Injector, NgModule, NgZone } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { ErrorHandlerService } from './error-handler.service';
import { ErrorScreensizeMonitorComponent } from './error-screensize-monitor/error-screensize-monitor.component';
import { ErrorMaintenanceComponent } from './error-maintenance/error-maintenance.component';
import { Error403Component } from './error-403/error-403.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    GenericErrorComponent,
    ErrorScreensizeMonitorComponent,
    ErrorMaintenanceComponent,
    Error403Component,
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    ErrorScreensizeMonitorComponent
  ],
  providers: [
    // ErrorHandlerService,
    {provide: ErrorHandler, useFactory: getErrorHandler, deps: [Injector, NgZone]},
    // {provide: ErrorHandler, useClass: ErrorHandlerService, deps: [Injector]},

  ]
})
export class ErrorsModule { }

export function getErrorHandler(injector: Injector, zone: NgZone): ErrorHandlerService {
  return new ErrorHandlerService(injector, zone);
}
