// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const DEFAULT_ENV = {
  production: false,
  // NOTE: This is already the DEV environment.
  firebase: {
    apiKey: 'AIzaSyDYwZ0l7yS-u44X1_pej9uC6n29i-w6wRo',
    authDomain: 'park-eval-dev.firebaseapp.com',
    databaseURL: 'https://park-eval-dev.firebaseio.com',
    projectId: 'park-eval-dev',
    storageBucket: 'park-eval-dev.appspot.com',
    messagingSenderId: '1017906437479',
    appId: '1:1017906437479:web:c26303408d84920a'
  },
  raygunApiKey: 'yyO4fOoKshUqvHh8RScxA',
  version: '4.1.20',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
